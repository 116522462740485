import React from 'react';
import { useLocation } from "react-router-dom";
import Intercom from '@intercom/messenger-js-sdk';

const SupportView = () => {
    const location = useLocation();

    // Créer un objet URLSearchParams à partir de la chaîne de requête
    const queryParams = new URLSearchParams(location.search);

    // Récupérer 'name' et 'email' de l'URL
    const name = queryParams.get('name');
    const email = queryParams.get('email');

    // Utiliser 'name' et 'email' pour initialiser Intercom
    Intercom({
        app_id: 'hq7jtuxz',
        name: name, // Utilisez la variable 'name' récupérée de l'URL
        email: email, // Utilisez la variable 'email' récupérée de l'URL
        created_at: `${Math.floor(Date.now() / 1000)}`, // Convertit en timestamp Unix (en secondes)
    });

    return (
        <div className={'h-screen '}>
            Test
        </div>
    );
};

export default SupportView;